import { darken } from 'polished';
import styled, {css} from 'styled-components'

export const Button = ({children,click,mode=1, borderRadius ="6px" }) => {
  return (
    <StyledButton clr={clr} onClick={click} mode={mode}   borderRadius={borderRadius} className="eus-buton-2 ">
      { children }
    </StyledButton>
  )
}
const StyledButton = styled.button(
  ({ mode, borderRadius }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    height : 44px ;
    background-color: ${mode ? `${clr?.buttons}` : clr?.buttons_text };
    color: ${mode ? clr?.buttons_text : "#000"};
    padding: 12px 24px;
    cursor: pointer;
    border: ${mode ? `1px solid ${clr?.buttons}` : `1px solid #D1D1D1`};
    text-align: center;
    width: fit-content;
    white-space: nowrap;
    border-radius: ${borderRadius};
    

    &:hover {
      background-color: ${mode ?  darken(0.05, clr?.buttons) : darken(0.05,clr?.buttons_text )};  
      border: ${mode ? `1px solid ${clr?.buttons}` : `1px solid #D1D1D1`}; 
    }

    &:focus {
      outline: none;
      background-color: ${mode ? darken(0.05, clr?.buttons) : clr?.buttons_text }; 
      box-shadow: ${mode ?  `0 0 0 1.5px ${clr?.buttons}` :  "0 0 0 1.5px #D1D1D1"}
     
  
    }
  `
);