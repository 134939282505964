import { Box, Divider, Drawer, IconButton, useMediaQuery } from "@mui/material";
import { Icon } from "components/Icons";
import React from "react";
import { useContactDrawer } from "./useContactDrawer";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { STATICS } from "api/api";

const ContactDrawer = () => {
  const { isOpen, toggle, unit } = useContactDrawer();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];
  const contactData =
    getFocus(_project, "ContactProperties")?.[pin]?.[pid] ?? [];

  return (
    <Drawer
      anchor={isMobile ? "bottom" : "right"}
      open={isOpen}
      onClose={() => toggle()}
      PaperProps={{
        sx: {
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { display: "none" },
          color: "secondary.contrastText",
          backgroundColor: "secondary.main",
          height: "100%",
        },
      }}
    >
      <Box
        sx={{
          width: isMobile ? "auto" : 422,
          position: "relative",
          pt: 4,
          pb: 4,
          px: 3,
          lineHeight: 1.3,
        }}
      >
        <IconButton
          onClick={toggle}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
          }}
        >
              <span className="icon-hover-effect" >
          <Icon
            icon="close"
            isFaIcon={true}
            size={"lg"}
            sx={{
              fontSize: 18,
            }}
          />
          </span>
        </IconButton>

        <Box sx={{ mt: 3, lineHeight: 1.5 }}>
          <Box sx={{ fontWeight: 700, mb: 1 }}>
            {
              buttonsLabel?.filter(
                (btn) => btn?.UILabelProperty === "Kontaktinformation"
              )[0]?.Label
            }
          </Box>
          {contactData?.map((item, i) => {
            if (
              !item?.Name &&
              !item?.AddressLine1 &&
              !item?.AddressLine2 &&
              !item?.Phone &&
              !item?.Email &&
              !item?.ImageUrl
            ) {
              return null;
            }

            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  alignItems: isMobile ? "flex-start" : "center",
                  justifyContent: "space-between",
                  mb: 3,
                }}
                key={i}
              >
                <Box sx={{ flex: isMobile ? "unset" : 1 }}>
                  {item?.Name && <p>{item?.Name}</p>}
                  {item?.AddressLine1 && <p>{item?.AddressLine1}</p>}
                  {item?.AddressLine2 && <p>{item?.AddressLine2}</p>}
                  {item?.Phone && <p>{item?.Phone}</p>}
                  {item?.Email && <p>{item?.Email}</p>}
                </Box>

                {item?.ImageUrl && (
                  <Box
                    sx={{
                      mt: isMobile ? 3 : 0,
                      flexShrink: 0,
                      display: "flex",
                      justifyContent: isMobile ? "center" : "flex-end",
                     
                    }}
                  >
                    <Box
                      component="img"
                      src={`${STATICS}${item?.ImageUrl}`}
                      alt="contact"
                      sx={{
                        width: "100%",
                        height: "auto",
                        maxWidth: isMobile ? "180px" : "180px",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Divider sx={{ my: 4, borderColor: `#fff` }} />

        {/* <ContactForm unit={unit}>
          <Box sx={{ fontWeight: 700, lineHeight: 1.4, mb: 3 }}>
            {
              buttonsLabel?.filter(
                (btn) =>
                  btn?.UILabelProperty === "SidefodformularOverskrift"
              )[0]?.Label
            }
            <br />
            <br />
            <span
              style={{
                fontSize: "0.95em",
                fontWeight: "normal",
              }}
            >
              {
                buttonsLabel?.filter(
                  (btn) =>
                    btn.UILabelProperty === "SidefodformularUnderoverskrift"
                )[0]?.Label
              }
            </span>
          </Box>
        </ContactForm> */}
      </Box>
    </Drawer>
  );
};

export default ContactDrawer;
